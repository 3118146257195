
import { Component, Vue } from "vue-property-decorator";
import PropertyActiveProcessCardShimmer from "@/components/PropertyActiveProcessCardShimmer.vue";

@Component({
  name: "PropertyInfoShimmer",
  components: {
    PropertyActiveProcessCardShimmer,
  },
})
export default class PropertyInfoShimmer extends Vue {}
