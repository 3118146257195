
import { Component, Prop, Vue } from "vue-property-decorator";
import HousfySvg from "@/components/ui/HousfySvg.vue";
import { HousfyDocumentCard } from "housfy-ui-lib";
import { resolveRoutePath } from "@/router";

@Component({
  name: "PropertyDocuments",
  components: { HousfySvg, HousfyDocumentCard },
})
export default class PropertyDocuments extends Vue {
  @Prop({ type: String, required: true })
  propertyUuid!: string;

  handleDocumentsButtonClick() {
    const route = resolveRoutePath({
      name: "documents",
      params: {
        propertyUuid: this.propertyUuid,
      },
    });

    this.$router.push(route);
  }
}
